import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Simple init for react components
 *
 * @param className
 * @param reactComponent
 * @param reactProps
 */
const initReact = (className, reactComponent, reactProps = {}) => {
  const componentNode = window.document.querySelector(`.component.${className}`);

  if (!componentNode) {
    return;
  }

  const componentLoaderNode = componentNode.querySelector('.component-loader');
  const componentContainerNode = componentNode.querySelector('.component-container');

  if (!componentLoaderNode || !componentContainerNode) {
    throw new Error(`Component ${className} has invalid html structure`);
  }

  const rawProps = componentNode.getAttribute('data-props');

  let props;
  if (!rawProps) {
    props = {};
  } else {
    try {
      props = JSON.parse(rawProps);
    } catch (_) {
      throw new Error(`Component ${className} has invalid props format`);
    }
  }

  props = { ...props, ...reactProps };

  const reactElement = React.createElement(reactComponent, props);
  ReactDOM.render(reactElement, componentContainerNode, () => {
    componentLoaderNode.classList.add('hidden');
  });
};

/**
 * Init temporary react component in temporary div
 *
 * @param reactComponent
 * @param reactProps
 */
const initReactTemporary = (reactComponent, reactProps = {}) => {
  const temporaryComponentNode = document.createElement('div');
  document.body.appendChild(temporaryComponentNode);

  const unmount = () => {
    ReactDOM.unmountComponentAtNode(temporaryComponentNode);
    temporaryComponentNode.remove();
  };

  const reactElement = React.createElement(reactComponent, { ...reactProps, unmount });
  ReactDOM.render(reactElement, temporaryComponentNode, () => {});
};

export default {
  initReact,
  initReactTemporary,
};
