import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import Component from 'javascripts/component';
import WarnetClient from 'javascripts/WarnetClient';
import ImageZoom from 'javascripts/ImageZoom';

/**
 * Preload all images
 */
require.context('images', true);

/**
 * Preload all fonts
 */
require.context('fonts', true);

/**
 * Activate only data-method attribute for links
 */
Rails.delegate(document, Rails.linkClickSelector, 'click', Rails.handleMethod);

/**
 * Disable turbo by default
 */
Turbo.session.drive = false;

/**
 * Activate scroll restoration
 */
const scrollRestorationInit = () => {
  if (!('scrollRestoration' in window.history)) return;

  window.history.scrollRestoration = 'auto';
};
scrollRestorationInit();

/**
 * Activate donation highlight comment text animation
 */
const donationHighlightAnimationInit = () => {
  const DURATION_IN_MS = 100;
  const donations = document.querySelectorAll('.donation--highlight');

  donations.forEach((donation) => {
    const donationComment = donation.querySelector('.donation-comment');
    const donationReply = donation.querySelector('.donation-reply');

    const newDonationCommentContent = Array.from(
      donationComment.innerText,
      (symbol) => {
        const span = document.createElement('span');
        const text = document.createTextNode(symbol);

        span.classList.add('donation-comment-symbol');
        span.classList.add('-hidden');
        span.appendChild(text);

        return span;
      },
    );

    // eslint-disable-next-line no-param-reassign
    donationComment.innerHTML = '';
    newDonationCommentContent.forEach((e) => donationComment.appendChild(e));

    donationComment.querySelectorAll('.donation-comment-symbol').forEach((symbol, index) => {
      setTimeout(() => symbol.classList.remove('-hidden'), DURATION_IN_MS * index);
    });

    if (donationReply) {
      donationReply.classList.add('-hidden');
      setTimeout(() => donationReply.classList.remove('-hidden'), DURATION_IN_MS * newDonationCommentContent.length);
    }
  });
};
donationHighlightAnimationInit();

/**
 * Add animations to turbo stream rendering
 */
const turboStreamAnimationsInit = () => {
  document.addEventListener('turbo:before-stream-render', (event) => {
    const streamElement = event.target;

    /**
     * Animate only when action equal replace and target is single
     */
    if (streamElement.action !== 'replace' || !streamElement.target) return;

    /**
     * Animate only if target element exists
     */
    const targetElement = document.getElementById(streamElement.target);
    if (!targetElement) return;

    /**
     * Animate only if both classes declared
     */
    const currentElement = streamElement.templateElement.content.firstElementChild;
    const { streamEnterClass, streamExitClass } = currentElement.dataset;
    if (!streamEnterClass || !streamExitClass) return;

    /**
     * Prevent this event => we have to call performAction manually
     * And add classes
     */
    event.preventDefault();
    targetElement.addEventListener('animationend', () => streamElement.performAction());
    currentElement.classList.add(streamEnterClass);
    targetElement.classList.add(streamExitClass);
  });
};
turboStreamAnimationsInit();

/**
 * Init actions
 */
const actionsInit = () => {
  /**
   * Client for warnet api
   */
  const warnetClient = new WarnetClient(Rails.csrfToken());

  /**
   * Messages for dialogs
   */
  const areYouSureMessage = 'Вы уверены?';
  const systemFailureMessage = 'Не удалось выполнить действие, произошла системная ошибка, обратитесь к администратору';

  const buildAction = (action) => (
    (id) => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (!confirm(areYouSureMessage)) {
        return;
      }

      warnetClient[action](id)
        .then(() => {
          window.location.reload(true);
          return null;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          // eslint-disable-next-line no-alert
          alert(systemFailureMessage);
        });
    }
  );

  const addClickEventHandlerOn = (elements, handler) => {
    elements.forEach((element) => {
      element.addEventListener('click', (event) => {
        handler(event.target.dataset.id);
      });
    });
  };

  const postDiscardElements = document.querySelectorAll('.post-discard');
  const postUndiscardElements = document.querySelectorAll('.post-undiscard');
  const postDestroyElements = document.querySelectorAll('.post-destroy');
  const commentDiscardElements = document.querySelectorAll('.comment-discard');
  const commentUndiscardElements = document.querySelectorAll('.comment-undiscard');
  const commentDestroyElements = document.querySelectorAll('.comment-destroy');
  const donationUpdateElements = document.querySelectorAll('.donation-update');
  const donationDiscardElements = document.querySelectorAll('.donation-discard');

  const postDiscardAction = buildAction('discardPost');
  const postUndiscardAction = buildAction('undiscardPost');
  const postDestroyAction = buildAction('destroyPost');
  const commentDiscardAction = buildAction('discardComment');
  const commentUndiscardAction = buildAction('undiscardComment');
  const commentDestroyAction = buildAction('destroyComment');
  const donationUpdateAction = (id) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const reply = prompt('Введите ответ:');

    if (reply === null) return;

    warnetClient.updateDonation(id, { reply })
      .then(() => {
        window.location.reload(true);
        return null;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        // eslint-disable-next-line no-alert
        alert(systemFailureMessage);
      });
  };
  const donationDiscardAction = buildAction('discardDonation');

  addClickEventHandlerOn(postDiscardElements, postDiscardAction);
  addClickEventHandlerOn(postUndiscardElements, postUndiscardAction);
  addClickEventHandlerOn(postDestroyElements, postDestroyAction);
  addClickEventHandlerOn(commentDiscardElements, commentDiscardAction);
  addClickEventHandlerOn(commentUndiscardElements, commentUndiscardAction);
  addClickEventHandlerOn(commentDestroyElements, commentDestroyAction);
  addClickEventHandlerOn(donationUpdateElements, donationUpdateAction);
  addClickEventHandlerOn(donationDiscardElements, donationDiscardAction);
};
actionsInit();

/**
 * Init flash messages
 */
const flashInit = () => {
  const flashMessageCloseElements = document.querySelectorAll('.flash-messages-list-item-close');

  flashMessageCloseElements.forEach((flashMessageCloseElement) => {
    flashMessageCloseElement.addEventListener('click', (event) => {
      const closeElement = event.currentTarget;
      closeElement.closest('.flash-messages-list-item')
        .remove();
    });
  });
};
flashInit();

/**
 * Mark images available for zoom if original image greater then actual image on screen
 * @note only on posts#show page
 */
const zoomableInit = () => {
  if (!('ResizeObserver' in window)) return;

  const imageElements = document.querySelectorAll('.post-content section .image-wrapper picture img');

  // eslint-disable-next-line compat/compat
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { width, height } = entry.contentRect;
      let { srcWidth, srcHeight } = entry.target.dataset;

      if (!srcWidth || !srcHeight) return;

      srcWidth = parseInt(srcWidth, 10);
      srcHeight = parseInt(srcHeight, 10);

      if (Number.isNaN(srcWidth) || Number.isNaN(srcHeight)) return;

      /**
       * If current dimensions less then source dimensions then this is zoomable image
       */
      const imageWrapperElement = entry.target.parentElement.parentElement;
      if (width < srcWidth || height < srcHeight) {
        imageWrapperElement.classList.add('zoomable');
      } else {
        imageWrapperElement.classList.remove('zoomable');
      }
    });
  });

  imageElements.forEach((imageElement) => resizeObserver.observe(imageElement));
};
zoomableInit();

/**
 * Initialize image zoom
 */
const zoomInit = () => {
  const handleZoom = (image) => {
    const { src: path, srcWidth, srcHeight } = image.dataset;

    const width = parseInt(srcWidth, 10);
    const height = parseInt(srcHeight, 10);

    Component.initReactTemporary(ImageZoom, {
      path,
      width,
      height,
    });
  };

  const imageElements = document.querySelectorAll('.image-wrapper');
  imageElements.forEach((imageElement) => {
    imageElement.addEventListener('click', () => {
      if (!imageElement.classList.contains('zoomable')) return;

      handleZoom(imageElement.querySelector('img'));
    });
  });
};
zoomInit();
