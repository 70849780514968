import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import Modal from 'react-modal';
import PrismaZoom from 'react-prismazoom';
import imageZoomStyles from './index.module.css';

/**
 * Set app element for correct applying aria-hidden attribute
 */
Modal.setAppElement('#app');

/**
 * @param {string} path url to source of original image
 * @param {number} width original image width
 * @param {number} height original image height
 * @returns {React.JSX.Element}
 */
function ImageZoomFooter({
  path,
  width,
  height,
}) {
  return (
    <div className={imageZoomStyles.footer}>
      <span className={imageZoomStyles.footerContent}>
        {width}
        x
        {height}
        {' | '}
        <a href={path} download>скачать</a>
      </span>
    </div>
  );
}

ImageZoomFooter.defaultProps = {
};

ImageZoomFooter.propTypes = {
  path: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

ImageZoomFooter.displayName = 'ImageZoomFooter';

/**
 * @param {string} path url to source of original image
 * @param {number} width original image width
 * @param {number} height original image height
 * @param {Function} unmount this function unmounts (destroys) current component
 * @returns {React.JSX.Element}
 */
function ImageZoom({
  path,
  width,
  height,
  unmount,
}) {
  const {
    width: documentWidth,
    height: documentHeight,
  } = useWindowSize();

  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const onRequestClose = () => {
    document.body.classList.remove('overflow-hidden');
    setIsOpen(false);
  };

  /**
   * For detecting pure mouse click event without move
   */
  const isMouseClickRef = useRef(true);

  /**
   * Clear all scroll locks on unmount
   */
  useEffect(() => document.body.classList.remove('overflow-hidden'));

  /**
   * Select base dimension for image view
   */
  const documentRatio = documentWidth / documentHeight;
  const imageRatio = width / height;

  let imageWidth;
  let imageHeight;

  /**
   * If more elongated image than the screen then width is base dimension, otherwise - height
   */
  if (imageRatio >= documentRatio) {
    // width is base dimension
    imageWidth = Math.min(width, documentWidth);
    imageHeight = imageWidth / imageRatio;
  } else {
    // height is base dimension
    imageHeight = Math.min(height, documentHeight);
    imageWidth = imageHeight * imageRatio;
  }

  const imageStyle = {
    width: imageWidth,
    height: imageHeight,
  };

  const contentStyle = {
    position: 'absolute',
    width: imageWidth,
    height: imageHeight,
    left: (documentWidth - imageWidth) / 2,
    top: (documentHeight - imageHeight) / 2,
  };

  const onMouseDown = (event) => {
    /**
     * Set to true it is left button click
     */
    isMouseClickRef.current = event.nativeEvent.button === 0;
  };

  const onTouchStart = () => {
    isMouseClickRef.current = true;
  };

  const onMouseMoveOrTouchMove = () => {
    isMouseClickRef.current = false;
  };

  const onMouseUpOrTouchEnd = () => {
    /**
     * If it is not just left click or tap - return
     */
    if (isMouseClickRef.current === false) return;

    onRequestClose();
  };

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        contentRef={(node) => { contentRef.current = node; }}
        isOpen={isOpen}
        onAfterOpen={() => { document.body.classList.add('overflow-hidden'); }}
        onRequestClose={onRequestClose}
        onAfterClose={() => { unmount(); }}
        portalClassName={imageZoomStyles.modal}
        overlayClassName={imageZoomStyles.modalOverlay}
        className={imageZoomStyles.modalContent}
        bodyOpenClassName=""
        htmlOpenClassName=""
        style={{
          content: contentStyle,
        }}
      >
        <PrismaZoom style={{ fontSize: 0 }}>
          <img
            src={path}
            style={imageStyle}
            alt=""
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMoveOrTouchMove}
            onMouseUp={onMouseUpOrTouchEnd}
            onTouchStart={onTouchStart}
            onTouchMove={onMouseMoveOrTouchMove}
            onTouchEnd={onMouseUpOrTouchEnd}
          />
        </PrismaZoom>
      </Modal>
      <ImageZoomFooter path={path} width={width} height={height} />
    </>
  );
}

ImageZoom.defaultProps = {
  unmount: () => undefined,
};

ImageZoom.propTypes = {
  path: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  unmount: PropTypes.func,
};

ImageZoom.displayName = 'ImageZoom';

export default ImageZoom;
